import React from "react";

export const SuccessIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="50" fill="#2BC128" />
      <path
        d="M72.5198 33.9545C73.5896 35.0037 73.6063 36.7215 72.557 37.7913L44.8826 66.0084C44.3756 66.5254 43.6829 66.8182 42.9587 66.8217C42.2346 66.8252 41.5391 66.5391 41.0271 66.0271L27.4612 52.4612C26.4016 51.4016 26.4016 49.6837 27.4612 48.6242C28.5207 47.5646 30.2386 47.5646 31.2982 48.6242L42.9269 60.2528L68.6829 33.9917C69.7322 32.9219 71.45 32.9053 72.5198 33.9545Z"
        fill="white"
      />
    </svg>
  );
};
