import React from "react";

export const CloseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6567 15.5425C14.1774 16.0632 15.0217 16.0632 15.5424 15.5425C16.0631 15.0218 16.0631 14.1776 15.5424 13.6569L9.88552 8.00003L15.5424 2.34319C16.0631 1.82249 16.0631 0.978268 15.5424 0.457568C15.0217 -0.0631307 14.1774 -0.0631307 13.6567 0.457568L7.9999 6.11441L2.34304 0.457541C1.82234 -0.0631584 0.978119 -0.063158 0.45742 0.457541C-0.0632789 0.97824 -0.0632794 1.82246 0.45742 2.34316L6.11429 8.00003L0.457419 13.6569C-0.0632805 14.1776 -0.0632801 15.0218 0.457419 15.5425C0.978118 16.0632 1.82234 16.0632 2.34304 15.5425L7.9999 9.88564L13.6567 15.5425Z"
        fill="#1E202C"
      />
    </svg>
  );
};
