import { TickIcon } from "assets/icons/Tick";
import { CrossIcon } from "assets/icons/Cross";
import { ButtonDefault, ButtonPrimary, ButtonSecondary } from "components/form";
import H2 from "components/headings/H2";
import Modal from "components/modal";
import { Spinner } from "components/spinner/Spinner";
import { useAuth } from "contexts/Auth/AuthContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserSubscriptionService from "services/userSubscription.service";
import { formatTrialEndDate, getSubscription } from "utils";
import { LoadingIndicator } from "components/spinner/LoadingIndicator";

const Billing = () => {
  const non_renewing = "non_renewing";
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [typeOfSubscription, setTypeOfSubscription] = useState({
    periodUnit: "Year",
    price: 95.99,
    periodValue: 2,
    name: "Pro Yearly",
    isMonthly: false,
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const { subscriptionState } = getSubscription();

  let subscription: any = localStorage.getItem("subscription");
  const subscriptionData = JSON.parse(subscription);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const executeScroll = () => window.scrollTo(0, 500);

  useEffect(() => {
    if (auth.user?.requestInitializeDefaultPlace) {
      return navigate("/setup-menu");
    }
    if (subscriptionState == "no_subscription") {
      return navigate("/login");
    }
  }, [auth.user?.userId]);

  useEffect(() => {
    const updateSubscriptionStatus = () => {
      setSubscriptionStatus(subscriptionState);
    };
    updateSubscriptionStatus();
    window.addEventListener("storage", updateSubscriptionStatus);
    setLoading(false);
    return () =>
      window.removeEventListener("storage", updateSubscriptionStatus);
  }, []);

  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  }, []);

  const onSubscribe = async (isResubscribe: boolean) => {
    try {
      setLoading(true);
      if (
        isResubscribe ||
        (subscriptionData.status == non_renewing &&
          subscriptionData.userPlan?.period === typeOfSubscription.periodUnit)
      ) {
        const response =
          await UserSubscriptionService.resumeCancelledSubscription(
            auth.getCurrentUser()?.userId
          );
        if (response?.data) {
          localStorage.setItem("subscription", JSON.stringify(response.data));
        }
      } else {
        const response = await UserSubscriptionService.getPaymentLinkById(
          auth.getCurrentUser()?.userId,
          typeOfSubscription.periodValue
        );
        if (response?.data) {
          window.location.replace(response?.data?.url);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdatePaymentMethod = async () => {
    try {
      setLoading(true);
      const response =
        await UserSubscriptionService.getUpdatePaymentMethodLinkById(
          auth.getCurrentUser()?.userId,
          window.location.href
        );

      if (response?.data) {
        window.location.replace(response?.data?.url);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onContactUsClick = async () => {
    window.open("https://jivo.chat/9vTvT12IiJ", "_blank");
  };
  function handleOpenModal() {
    setIsModalOpen(true);
  }
  function handleCloseModal() {
    setIsModalOpen(false);
  }
  const handleYesAction = async () => {
    try {
      let response = await UserSubscriptionService.cancelUserSubscription(
        auth.getCurrentUser().userId
      );
      if (response) {
        localStorage.setItem("subscription", JSON.stringify(response.data));
        setIsModalOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCurrentSubscriptionStatus = (): string => {
    const oneDate = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if (subscriptionData.status === "in_trial") {
      const today = new Date();
      const expiredDate = new Date(subscriptionData.userPlan?.trialEnd);
      const dateDiff = Math.round(
        Math.abs(expiredDate.valueOf() - today.valueOf()) / oneDate
      );
      return `In Trial - ${
        dateDiff < 1 ? `End today` : `${dateDiff} days left`
      }`;
    }

    if (subscriptionData.status === "non_renewing") {
      const today = new Date();
      const expiredDate = new Date(subscriptionData.expiredAt);
      const dateDiff =
        Math.round(
          Math.abs(expiredDate.valueOf() - today.valueOf()) / oneDate
        ) - 7;
      return dateDiff < 0
        ? "Expired"
        : `Expiring soon - ${dateDiff < 1 ? `Today` : `${dateDiff} days left`}`;
    }

    return "";
  };

  const isCurrentPlanShown = (): boolean => {
    const statusesShown = ["in_trial", "active", "non_renewing"];
    return statusesShown.includes(subscriptionData.status);
  };

  const onUpdateTypeOfSubscription = (isMonthly: boolean) => {
    setTypeOfSubscription((prev) => {
      return {
        ...prev,
        isMonthly,
        periodUnit: isMonthly ? "Month" : "Year",
        periodValue: isMonthly ? 1 : 2,
        price: isMonthly ? 9.99 : 95.99,
        name: isMonthly ? "Pro" : "Pro Yearly",
      };
    });
  };

  return pageLoading ? (
    <LoadingIndicator></LoadingIndicator>
  ) : (
    subscriptionData && (
      <>
        <div className="px-4 md:px-0 pb-[100px]">
          <div className="flex col justify-between align-center">
            <H2>Billing</H2>
            <div className="flex">
              <ButtonSecondary
                styles="mr-4"
                onClick={() => onUpdatePaymentMethod()}
              >
                {loading && <Spinner />}
                <span className="align-center">Update Payment Method</span>
              </ButtonSecondary>
              <ButtonDefault onClick={() => navigate("/invoices")}>
                <span className="align-center">Invoices</span>
              </ButtonDefault>
            </div>
          </div>
          {subscriptionData.userPlan && isCurrentPlanShown() && (
            <div className="flex flex-col">
              <div className="flex justify-center m-8">
                <div className="bg-white px-6 py-3 rounded-2xl shadow-white shadow-2xl w-[375px] md:w-[782px] lg:w-[782px]">
                  <span className="text-neutral-80 font-bold text-sm">
                    Current Plan
                  </span>
                  <div className="flex justify-between h-10 mb-1 mt-1">
                    <div className="flex h-15 pb-1 flex-wrap content-center">
                      <span className="text-base font-bold mr-3">
                        {subscriptionData.userPlan?.name}
                      </span>
                      {subscriptionData.userPlan?.coupon && (
                        <span className="text-primary-light font-bold mb-3 bg-neutral-10 rounded-full px-3 py-1 text-sm text-center">
                          Discount applied
                        </span>
                      )}
                      {getCurrentSubscriptionStatus() && (
                        <div className="text-sm text-orange-60 font-bold mb-3 mt-[2px] bg-neutral-10 rounded-full px-3 text-sm text-center">
                          {getCurrentSubscriptionStatus()}
                        </div>
                      )}
                    </div>
                    {subscriptionData.status === "in_trial" && (
                      <ButtonPrimary
                        styles="px-[0px] font-normal"
                        onClick={executeScroll}
                      >
                        <div className="flex items-center justify-center w-[110px]">
                          <span>Upgrade now</span>
                        </div>
                      </ButtonPrimary>
                    )}
                    {subscriptionData.status === "active" && (
                      <ButtonDefault
                        styles="px-[0px] font-normal"
                        onClick={handleOpenModal}
                      >
                        {loading && <Spinner />}
                        <div className="flex items-center justify-center w-[130px]">
                          <span>Cancel subscription</span>
                        </div>
                      </ButtonDefault>
                    )}
                    {subscriptionData.status === "non_renewing" && (
                      <ButtonPrimary
                        styles="px-[0px] font-normal"
                        onClick={() => onSubscribe(true)}
                      >
                        {loading && <Spinner />}
                        <div className="flex items-center justify-center w-[110px]">
                          <span>Resubscribe</span>
                        </div>
                      </ButtonPrimary>
                    )}
                  </div>
                  {subscriptionData.userPlan?.paidAmount !=
                    subscriptionData.userPlan?.price && (
                    <span className="text-neutral-40">
                      {`$${Number(
                        subscriptionData.userPlan?.paidAmount / 100
                      )}`}{" "}
                    </span>
                  )}
                  <span
                    className={`${
                      subscriptionData.userPlan?.paidAmount !=
                      subscriptionData.userPlan?.price
                        ? "line-through text-neutral-30"
                        : "text-neutral-40"
                    }`}
                  >{`$${Number(subscriptionData.userPlan?.price / 100)}`}</span>
                  <span className="text-neutral-40">
                    /{subscriptionData.userPlan?.period?.toLowerCase()}
                  </span>
                  {subscriptionData.status === "active" && (
                    <span className="text-neutral-40">
                      {" "}
                      -{" "}
                      {`Next billing on: ${formatTrialEndDate(
                        subscriptionData.nextBillingAt
                      )}`}
                    </span>
                  )}
                  {subscriptionData.status === "non_renewing" && (
                    <span className="text-neutral-40">
                      {" "}
                      -{" "}
                      {`Active Until: ${formatTrialEndDate(
                        subscriptionData.cancelledAt
                      )}`}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-center">
                <hr className="h-[2px] w-[175px] bg-neutral-60 border-0 rounded"></hr>
              </div>
            </div>
          )}

          <div className="flex justify-center">
            <div className="flex flex-col">
              <div className="flex justify-center mt-8">
                <span className="text-2xl font-bold">ALL PLANS</span>
              </div>
            </div>
          </div>
          <div className="flex justify-center m-8">
            <div className="flex w-auto bg-neutral-20 border border-neutral-20 rounded-xl p-1">
              <button
                onClick={() => {
                  onUpdateTypeOfSubscription(true);
                }}
                className={`flex items-center justify-center rounded-xl border font-semibold transition w-auto text-sm py-2 px-3.5 h-10
              ${
                !typeOfSubscription.isMonthly
                  ? "bg-neutral-20 hover:bg-neutral-30 border-neutral-20 text-[#454a5f]"
                  : "bg-primary-light hover:bg-teal-70 border-primary-light text-white"
              }`}
                type="button"
              >
                <span>Monthly</span>
              </button>
              <button
                onClick={() => {
                  onUpdateTypeOfSubscription(false);
                }}
                className={`flex items-center justify-center rounded-xl border font-semibold transition w-auto text-sm py-2 px-3.5 h-10
              ${
                typeOfSubscription.isMonthly
                  ? "bg-neutral-20 hover:bg-neutral-30 border-neutral-20 text-[#454a5f]"
                  : "bg-primary-light hover:bg-teal-70 border-primary-light text-white"
              }`}
                type="button"
              >
                <span>Yearly (20% Discount)</span>
              </button>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-2xl flex flex-col justify-between shadow-white shadow-2xl w-[375px]">
                <h2 className="text-lg w-1/2 text-orange-60 font-bold mb-3 bg-neutral-10 rounded-full px-3 py-1 text-sm text-center uppercase">
                  {typeOfSubscription.name}
                </h2>
                <p className="flex justify-start items-baseline text-46px font-medium mb-2">
                  {`$${typeOfSubscription.price}`}
                  <span className="text-[16px] pl-2 uppercase text-neutral-30">
                    /{typeOfSubscription.periodUnit}
                  </span>{" "}
                </p>
                <h3 className="text-[16px] text-neutral-40 font-normal mb-6">
                  Suitable for established business, enjoy all support and
                  unlimited items.
                </h3>

                <ul className="list-disc list-inside mb-4">
                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />1 digital menu
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Unlimited items per menu
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Unlimited QR scans
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Full menu customization
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Support converting from PDF menu
                  </li>

                  <li className="flex items-center text-red-500 mb-1">
                    <CrossIcon />
                    Custom domain
                  </li>

                  <li className="flex items-center text-red-500 mb-1">
                    <CrossIcon />
                    Unique menu page design
                  </li>
                </ul>
                <ButtonPrimary
                  onClick={() => onSubscribe(false)}
                  styles="w-full"
                  disabled={
                    subscriptionStatus === "active" &&
                    subscriptionData.isSubScriptionUpToDate &&
                    subscriptionData.billingPeriodUnit.toLowerCase() ===
                      typeOfSubscription.periodUnit.toLowerCase() &&
                    subscriptionData.status !== non_renewing
                  }
                >
                  <div className="flex items-center justify-center">
                    {loading && <Spinner />}
                    <span>
                      {loading
                        ? "Please wait..." || "Subscribe"
                        : !loading &&
                          subscriptionStatus === "active" &&
                          subscriptionData.isSubScriptionUpToDate &&
                          subscriptionData.billingPeriodUnit.toLowerCase() ===
                            typeOfSubscription.periodUnit.toLowerCase() &&
                          subscriptionData.status !== non_renewing
                        ? "Current Plan"
                        : "Subscribe"}
                    </span>
                  </div>
                </ButtonPrimary>
              </div>
              <div className="bg-white p-6 rounded-2xl flex flex-col justify-between shadow-white shadow-2xl w-[375px]">
                <h2 className="text-lg w-1/2 text-primary-light font-bold mb-3 bg-neutral-10 rounded-full px-3 py-1 text-sm text-center uppercase">
                  CUSTOM
                </h2>
                <p className="flex justify-start items-baseline text-4xl font-medium mb-6 mt-2">
                  Contact us{" "}
                </p>
                <h3 className="text-[16px] text-neutral-40 font-normal mb-6">
                  Need special feature or have custom request?
                  <br />
                  Just send us a message.
                </h3>
                <ul className="list-disc list-inside mb-4">
                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Unlimited digital menus
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Unlimited items per menu
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Unlimited QR scans
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Full menu customization
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Support converting from PDF menu
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Custom domain
                  </li>

                  <li className="flex items-center text-green-500 mb-1">
                    <TickIcon />
                    Unique menu page design
                  </li>
                </ul>
                <ButtonPrimary onClick={onContactUsClick} styles="w-full">
                  <div className="flex items-center justify-center">
                    <span>{"Contact Us"}</span>
                  </div>
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        <Modal
          className={"max-w-[500px]"}
          show={isModalOpen}
          onClose={handleCloseModal}
        >
          <h2 className="text-22px font-bold leading-8 text-[#454A5F]">
            Cancel Pro subscription?
          </h2>
          <p className="text-[16px] leading-6 text-[#454A5F] font-normal">
            Are you sure you want to cancel{" "}
            <span style={{ fontWeight: "bold" }}>Pro subscription</span>? After{" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {`${formatTrialEndDate(subscriptionData.nextBillingAt)}, `}
            </span>
            you will no longer be able to edit your menu and your customers will
            no longer see your menu.
          </p>
          <div className="flex justify-end mt-4">
            <ButtonDefault
              styles="!w-auto sm:mr-4 !text-default-error !border-default-error !font-medium"
              onClick={handleYesAction}
            >
              Yes, I want to cancel
            </ButtonDefault>
            <ButtonPrimary onClick={handleCloseModal}>
              No, I don’t want to cancel
            </ButtonPrimary>
          </div>
        </Modal>
      </>
    )
  );
};

export default Billing;
